<template>
    <div>
    <button id="button_wishlist" class="ol-btn ol-btn-wishlist" @click="goBack">Kembali</button>
    <iframe
      :src="this.inspiration.link" allow="vr" allowvr="yes" allowfullscreen="yes"
      style="position: fixed; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; border: none; margin: 0; padding: 0; overflow: hidden; z-index: 999999;"
    >
      Your browser doesn't support iframes
    </iframe>
  </div>
</template>
<script>

import "@/assets/css/bootstrap.css";
require("@/assets/js/imagesloaded.pkgd.min.js");
require("@/assets/js/masonry.pkgd.min.js");
require("@/assets/js/bootstrap.js");
import globalUrl from '@/util/globallUrl'
import BaseLayout from '@/components/landing/BaseLayout.vue';
import SkeletonLoader from '@/components/loading/skeletonLoader'
import ProductList from "@/components/product/ProductList.vue"
import ProductLoader from '@/components/loading/product/Content.vue'
import {mapActions,mapGetters} from "vuex";

export default {
    name: 'DetailInpirasi',
    components:{
        BaseLayout,
        SkeletonLoader,
        ProductList,
        ProductLoader
    },
    data(){
        return{
            loading:true,
            loadingWishlist:false,
            inspiration:null,
            product:null,
            listProduct:[],
            inspiration_other:[]
        }
    },
    computed:{
        ...mapGetters('auth', ['location','authenticated']),
    },
    methods:{
        goBack() {
            // Use Vue Router to navigate back to the previous page
            this.$router.go(-1);
        },
        async getData(){
            this.loading=false;
            // await globalUrl.WS_INSPIRASI.GET_DETAIL(this.$route.params.slug).then(response => {
            //     let _data = response.data.data;
            //     this.inspiration=_data.inspiration;
            //     this.product = _data.product;
            //     this.loading=false;
            //     console.log(this.inspiration);
                
            // }).catch(error => {
            //     // console.log(error.data)
            //     this.loading=false;
            // });
        },
       
    },
    async mounted(){
        await this.getData();
    },
    
    beforeRouteUpdate(to, from, next) {
        
        this.getData();
        next();
    }


}
</script>
<style>
/* Add styling for the button with absolute position */
button#button_wishlist {
    position: absolute;
  top: 10px; /* Adjust the top position as needed */
  left: 10px; /* Adjust the left position as needed */
  z-index: 1000000; /* Ensure the button is above the iframe */
}

.ol-btn-wishlist{
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
</style>